// NOTE: this is JS custom code for AMP

import Rails from '@rails/ujs';
import Sortable from 'sortablejs';

document.addEventListener('turbolinks:load', () => {
  // Close flash messages
  let close_icon = document.getElementById('close');
  if(close_icon != undefined){
    function closeParent() {
      close_icon.parentElement.style.display = 'none'
    }
    close_icon.onclick = closeParent;
  }

  // Menu control
  let open_menu = document.getElementById('open-menu');
  if(open_menu != null){
    let close_menu = document.getElementById('close-menu');
    function openMenu() {
      document.getElementById('menu').style.width = '250px';
    }
    function closeMenu() {
      document.getElementById('menu').style.width = '0';
    }
    open_menu.onclick = openMenu;
    close_menu.onclick = closeMenu;
  }

  // New Service forever/permanent
  let forever_control = document.getElementById('forever-control');
  if(forever_control != null){
    let date_controls = document.getElementsByClassName('date');
    function foreverControl() {
      for(var i = 0; i < date_controls.length; i++){
        date_controls[i].disabled = forever_control.checked;
      }
    }
    forever_control.onclick = foreverControl;
  }
});

